.dynamic-nav {
  &--desktop {
    display: flex;
    justify-content: space-between;

    @include media-breakpoint-down(md) {
      display: none;
    }
  }

  &--mobile {
    padding: $spacing-s $spacing-m;

    .btn--language {
      @include media-breakpoint-down(xs) {
        font-size: 0.8rem;
      }
    }
  }

  &__logo-holder {
    display: flex;
  }

  &__brand {
    @include media-breakpoint-down(sm) {
      width: 120px;
    }

    img, svg {
      height: 100%;
      max-height: 50px;
    }

    img {
      max-width: 175px;
      width: auto;

      @media only screen and (max-width: 374px) {
        width: 100%;
      }
    }

    svg {
      width: 100%;
    }

    &:first-child {
      margin-right: 0;
      padding-right: $spacing-m;
    }

    &:last-child {
      border-left: 1px solid $digital-blue;
      margin-right: 0;
      padding-left: $spacing-m;
    }
  }
}
