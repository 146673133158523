[data-form-switch] form {
  display: none;
  &:first-child {
    display: block;
  }
}

.form {
  &__section {
    &--heading-success {
      margin-bottom: 3rem;
    }
  }
}

.login {
  &--error {
    background-color: #ffe3e2;
    border-radius: 0.5rem;
    color: #c00;
    font-weight: bold;
    margin-bottom: 0.5rem;
    padding: .5rem 1rem;
  }
}
